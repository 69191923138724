<template>
  <div class="page-container">
<!--    <div class="header" style="padding: 0;">-->
<!--      <page-header @navBack="$router.replace('/train')" pageTitle="学习报告"></page-header>-->
<!--    </div>-->
    <nav-bar title="学习报告"></nav-bar>
    <div class="report">
      <h2 class="title">机动车驾驶员理论培训学习证明</h2>
      <p class="content"><span class="underline">伍欣然</span>（身份证号：530121198903119561），在我市备案的<span class="underline">训美驾培APP</span>参加<span class="underline">机动车驾驶理论培训课程（C1C2C3）</span>远程教育培训，已被交通运输部规定的教学大纲完成培训内容，完成科目一：<span class="underline">9 学时</span>，科目四<span class="underline">8 学时</span>培训课程。</p>
      <p class="node">特此证明</p>
      <p class="infor">北京训美科技有限公司</p>
      <p class="infor">2021年06月19日</p>
    </div>
    <div class="record" style="padding-bottom: 30px;">
      <h2 class="title" style="margin-bottom: 5px;">学习记录</h2>
      <img width="100%" src="@/assets/xuexijilu.png" alt="">
    </div>
  </div>
</template>

<script>
import { NavBar } from 'vant';
export default {
  name: "study-report",
  components: { NavBar }
}
</script>

<style scoped>
.report { padding: 0 15px; margin-top: 50px; }
.report .title { text-align: center; font-size: 18px; margin: 0 0 30px; }
.report p { margin: 0; text-align: justify; font-size: 14px; line-height: 30px; }
.report p .underline{ text-decoration: underline; font-weight: 500; }
.report p.content { text-indent: 29px;  }
.report p.node { text-indent: 29px; margin: 20px 0; }
.report p.infor { text-align: right;}
.record { margin-top: 50px; padding: 0 10px; }
.record .title { text-align: center; font-size: 16px; margin: 0; line-height: 20px; }
</style>